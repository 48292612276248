.filepond--panel-root {
  background-color: #FAFAFA;
  border: 1px dashed #979797;
}
.filepond--file {
  background-color: #1DC4CA;
}
.filepond--drop-label {
  label {
    cursor: pointer !important;
  }
}
.filepond--wrapper {
  cursor: pointer !important;
}